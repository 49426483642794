import type { Permission } from '@/domain/permissions';
import type { PartyRoleType } from '@/domain/person';
import type { User } from '@/domain/user';
import { redirectToAuthClient } from '@/utils/redirectToAuthClient';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';

export function getAuthority(str?: string): string | string[] {
  const authorityString = typeof str === 'undefined' ? localStorage.getItem('roles') : str;
  let authority;
  try {
    if (authorityString) {
      authority = JSON.parse(authorityString);
    }
  } catch (e) {
    authority = authorityString;
  }
  if (typeof authority === 'string') {
    return [authority];
  }
  return authority;
}

const triggerLocalStorageEvent = (key: string) => {
  const ev = new StorageEvent('storage', { key });
  window.dispatchEvent(ev);
};

export function setAccessToken(token: string): void {
  localStorage.setItem('access_token', token);
  triggerLocalStorageEvent('access_token');
}

export function setRefreshToken(token: string): void {
  localStorage.setItem('refresh_token', token);
}

export const getAccessToken = () => localStorage.getItem('access_token');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const setPermissions = (permissions: Permission[]) => {
  localStorage.setItem('permissions', JSON.stringify(permissions));
  triggerLocalStorageEvent('permissions');
};

export const getPermissions = memoize(
  () => {
    const value = localStorage.getItem('permissions');

    if (!value) {
      return undefined;
    }

    return JSON.parse(value) as Permission[];
  },
  () => localStorage.getItem('permissions'),
);

export const getPermissionsFromUser = (user: User) => {
  const permissions = user.roles.map((role) => role.type.permissions).flat();
  return uniq(permissions).sort();
};

export const loggedIn = () => {
  const token = getRefreshToken();
  return !!token;
};

export function logout(): void {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('permissions');
  localStorage.removeItem('roles');
  localStorage.removeItem('user_profile');
  redirectToAuthClient();
}

export const hasPermissions = (
  key: Permission | Permission[],
  permissions = getPermissions() ?? [],
) =>
  typeof key === 'string'
    ? !!permissions?.includes(key)
    : !!key?.every((permission: Permission) => permissions?.includes(permission));

type ParsedJwt = {
  exp: number;
  organizationRoleTypes: PartyRoleType[];
  permissions: Permissions[];
  orgId: number;
  userId: number;
  loginId: number;
  trackId: number;
};

export const parseJwt = memoize(
  (token: string | null): null | ParsedJwt => {
    if (!token) {
      return null;
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    return JSON.parse(jsonPayload);
  },
  (token) => token,
);

export const getOrganizationId = memoize(
  () => {
    const accessToken = getAccessToken();
    const parsed = parseJwt(accessToken);

    return parsed?.orgId ?? null;
  },
  () => getAccessToken(),
);
