import type { EmailTemplate } from '@/domain/email-template';
import type { Effect, Model, Reducer } from '@/models/connect';
import { EmailTemplatesService } from '@/services/email-templates';
import { handleError } from '@/utils/utils';
import { message } from 'antd';

export interface EmailModelState {
  templates: EmailTemplate[];
}

type Effects = {
  search: Effect;
  delete: Effect;
};

type Reducers = {
  setTemplates: Reducer<EmailModelState>;
};

const initialState: EmailModelState = {
  templates: [],
};

const EmailModel: Model<EmailModelState, Effects, Reducers> = {
  namespace: 'email',

  state: { ...initialState },

  effects: {
    *search({ payload }, { call, put }) {
      const response = yield call(EmailTemplatesService.search, payload);

      yield put({
        type: 'setTemplates',
        payload: response,
      });
    },
    *delete({ payload }, { call, put, select }) {
      try {
        yield call(EmailTemplatesService.delete, payload);

        const templates = (yield select((state: any) => state.email.templates)) as EmailTemplate[];

        yield put({
          type: 'setTemplates',
          payload: templates.filter((template) => template.id !== payload.id),
        });

        message.success('Email template deleted');
      } catch (error) {
        handleError(error, { toastFallbackMessage: 'Delete Email template failed' });
      }
    },
  },

  reducers: {
    setTemplates(state, action) {
      return {
        ...state,
        templates: action.payload,
      };
    },
  },
};

export default EmailModel;
