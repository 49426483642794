import type { New, PropifyEntity } from './base';
import type { Entities, EntityType } from './entity-type';

export enum TicketStatus {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
  CANCELLED = 'CANCELLED',
  WRAP_UP = 'WRAP_UP',
  CLAIMED = 'CLAIMED',
}

export enum TicketType {
  UNREAD_SMS = 'UNREAD_SMS',
  UNREAD_EMAIL = 'UNREAD_EMAIL',
  VOICEMAIL = 'VOICEMAIL', // PN-3202
  FOLLOW_UP = 'FOLLOW_UP',
  INCOMING_CALL = 'INCOMING_CALL',
  OUTBOUND_SMS = 'OUTBOUND_SMS',
  OUTBOUND_CALL = 'OUTBOUND_CALL',
  OUTBOUND_EMAIL = 'OUTBOUND_EMAIL',
  AGENT_CREATED = 'AGENT_CREATED',
  SHOWING_SURVEY_COMPLETE = 'SHOWING_SURVEY_COMPLETE',
  UPCOMING_LEASE_END = 'UPCOMING_LEASE_END',
  APPLICANT = 'APPLICANT',
}

export enum TicketPriority {
  STANDARD = 'STANDARD',
  HIGH = 'HIGH',
  URGENT = 'URGENT',
  EMERGENCY = 'EMERGENCY',
}

export const GENERAL_SUBSKILL_ID = -1;

export interface Ticket extends PropifyEntity {
  reason?: string;
  reasonDescription?: string;
  status: TicketStatus;
  priority: TicketPriority;
  responseNeeded: boolean;
  terminationDescription?: string;
  terminationReason?: string;
  type: TicketType;
  dueTime: string;
  finishedTime?: string;
  ticketGroupId?: number;
  subskillId?: number;
  resolvedLoginId?: number;
  ticketClaimId?: number;
  ticketClaimUserId?: number;
  ticketClaimExpirationTime?: string;
  partyId?: number;
  parentTicketId?: number;
  preferredUserId?: number;
  entities?: Entities;
}

export interface CreateTicketRequest extends Omit<New<Ticket>, 'type' | 'responseNeeded'> {
  responseNeeded?: boolean;
}

export interface TicketSearchResult extends Ticket {
  agent?: string;
  contact: string;
  lastContact: string;
  subskill?: string;
  openTickets?: number;
}

export interface TicketReason extends PropifyEntity {
  id: number;
  reason: string;
  followUp?: boolean;
  ticketGroupId?: number;
  subskillId?: number;
}

export interface CRMTicketEventData {
  type: CRMTicketEventType;
  ticket: TicketSearchResult;
}

export enum CRMTicketEventType {
  TICKET_CREATED = 'TICKET_CREATED',
  TICKET_UPDATED = 'TICKET_UPDATED',
  TICKET_CLAIM_CREATED = 'TICKET_CLAIM_CREATED',
  TICKET_CLAIM_UPDATED = 'TICKET_CLAIM_UPDATED',
  TICKET_CLAIM_CANCELLED = 'TICKET_CLAIM_CANCELLED',
}

export interface TicketEntity {
  entityType: EntityType;
  entityId: number;
}

export interface TicketUpdateEntitiesRequest {
  entities: TicketEntity[];
}

export interface TicketWrapUpBody {
  followUp: boolean;
  resolved: boolean;
  responseNeeded: boolean;
  dueTime?: string;
  priority?: TicketPriority;
  reason?: string;
  description?: string;
  preferredUserId?: number;
}

export interface TicketBulkWrapUpRequest extends TicketWrapUpBody {
  ids: number[];
}
