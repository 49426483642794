import type { PropifyEntity } from '@/domain/base';
import type { Lease, LesseeType } from '@/domain/lease';
import type { Phone } from '@/domain/phone';
import type { Unit } from '@/domain/unit';
import type { MonetaryAmount } from '@propify/components';
import type { EmailAddress } from './emails';
import type { PartyRole, Person } from './person';
import type { Property } from './property';

export enum ResidentStatus {
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
  NOTICE = 'NOTICE',
  EVICTION = 'EVICTION',
  PAST = 'PAST',
  INACTIVE = 'INACTIVE',
  NON_RENEWAL = 'NON_RENEWAL',
  HOLDOVER = 'HOLDOVER',
  CANCELLED = 'CANCELLED',
}

export enum ResidentType {
  PRIMARY = 'PRIMARY',
  ADULT = 'ADULT',
  GUARANTOR = 'GUARANTOR',
  MINOR = 'MINOR',
}

export interface Resident extends PartyRole {
  status: ResidentStatus;
  type: ResidentType;
  firstName: string;
  lastName: string;
  salutation?: string;
  phones?: Phone[];
  tenantType?: string;
  balance?: MonetaryAmount;
  writeOffBalance?: MonetaryAmount;
  moveInDate?: string;
  moveOutDate?: string;
  moveOutReason?: string;
  smartHomeEnabledDate?: string;
  deposits?: Deposit[];
  emailAddresses?: EmailAddress[];
  unitId: number;
  unit?: Unit;
  renewable?: boolean;
  rentalPrice?: MonetaryAmount;
  subsidy?: boolean;
  collectionExternalLink?: string;
  foreignDbCode?: string;
  foreignDbId?: number;
  foreignDbResidentUrl?: string;
  foreignDbLedgerUrl?: string;
}

export interface ResidentSearchResult extends Resident {
  person?: Person;
  lease?: Lease;
  property?: Property;
}

export interface ResidentSearchParams {
  id?: number | number[];
  personId?: number;
  propertyId?: number;
  unitId?: number;
  foreignDbId?: number;
  keywords?: string;
  status?: ResidentStatus;
  type?: ResidentType;
  minBalance?: number;
  maxBalance?: number;
}

export interface ScheduledCharge extends PropifyEntity {
  chargeType?: ChargeType;
  startDate?: string;
  endDate?: string;
  amount?: MonetaryAmount;
  residentId?: number;
  leaseId?: number;
  foreignDbId?: number;
  foreignDbCode?: string;
  foreignDbType?: string;
}

export interface ScheduledChargesSearchResult extends ScheduledCharge {
  resident: Resident;
}

export interface ScheduledChargesSearchParams {
  scheduledChargeId?: number;
  propertyId?: number;
  residentId?: number | number[];
  unitId?: number;
}

interface ChargeType extends PropifyEntity {
  name?: string;
}

interface Deposit extends PropifyEntity {
  depositType?: DepositType;
  amount?: MonetaryAmount;
  residentId?: number;
}

interface DepositType extends PropifyEntity {
  name?: string;
}

export interface ResidentUpdateBalanceResponse {
  balance: number;
}

export interface Charge {
  name: string;
  posted?: string;
  amountDue: MonetaryAmount;
  amountPaid: MonetaryAmount;
}

export interface PropertyLessee {
  firstLeaseStartDate: string;
  lastLeaseEndDate: string;
  lesseeType: LesseeType;
  personFirstName: string;
  personId: number;
  personLastName: string;
  propertyId: number;
  residentId: number;
  residentStatus: ResidentStatus;
  residentMoveOutDate?: string;
}

export interface LedgerTransaction {
  id: string;
  date: string;
  code: string;
  description: string;
  notes: string;
  isCharge: boolean;
  chargeAmount: MonetaryAmount;
  paymentAmount: MonetaryAmount;
  balanceAmount: MonetaryAmount;
}
