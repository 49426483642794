import { getAccessToken, getRefreshToken, logout, parseJwt } from '@/utils/authority';
import { handleError } from '@/utils/utils';
import { message } from 'antd';
import type { AxiosResponse } from 'axios';
import Axios from 'axios';
import queryString from 'query-string';
import type { RefreshTokenResponse } from './auth';
import { AuthService } from './auth';

const JWT_EXPIRY_THRESHOLD = 5000;
let refreshPromise: Promise<RefreshTokenResponse> | undefined;

export const apiClient = Axios.create({
  baseURL: '/rest',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

const isJwtGood = (jwt: string | null) => {
  try {
    const parsedJwt = parseJwt(jwt);
    if (!parsedJwt) {
      return false;
    }
    return jwt && Date.now() < parsedJwt.exp * 1000 - JWT_EXPIRY_THRESHOLD;
  } catch (error) {
    handleError(error, { displayToast: false });
    return false;
  }
};

class ResponseError extends Error {
  response?: AxiosResponse;

  jsonResponse: any;

  textResponse: string;

  displayMessage: string;

  status?: number;

  constructor(msg: string, response?: AxiosResponse) {
    super(msg);
    this.response = response;
    this.jsonResponse = {};
    this.textResponse = '';
    this.displayMessage = msg;
  }
}

apiClient.interceptors.request.use((config) => {
  // No extra configuration for absolute URLs (i.e.: a file in S3)
  if (config.url!.charAt(0) !== '/') {
    return config;
  }

  if (config.url === '/auth/refresh') {
    return config;
  }

  const accessToken = getAccessToken();
  if (!isJwtGood(accessToken)) {
    const refreshToken = getRefreshToken();
    if (!isJwtGood(refreshToken)) {
      logout();
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new Axios.Cancel('The access and refresh tokens are both missing or expired');
    }

    refreshPromise = refreshPromise || AuthService.refreshToken({ refreshToken: refreshToken! });
    return refreshPromise
      .then((response) => {
        refreshPromise = undefined;
        // eslint-disable-next-line no-param-reassign
        config.headers = config.headers ?? {};
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${response.accessToken}`;
        return config;
      })
      .catch((error) => {
        handleError(error, { displayToast: false });
        logout();
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new Axios.Cancel('Error communicating with the authorization system.');
      });
  }

  // eslint-disable-next-line no-param-reassign
  config.headers = config.headers ?? {};
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      logout();
      return;
    }

    let errorText = `Unhandled status: ${error.response?.status}`;
    if (error.response?.status === 500) {
      errorText = 'Internal Server Error';
    }

    if (error.response?.status === 400) {
      errorText = 'Bad Request';
    }

    if (error.response?.status === 403) {
      errorText = 'Missing permission';
    }

    if (error.response?.status === 504) {
      errorText = 'Gateway timeout';
      message.error({
        content: 'Error communicating with remote server. Please check your internet connection.',
        // cspell:ignore NETGONE
        key: 'NETGONE',
      });
    }

    const responseError = new ResponseError(errorText, error.response);

    responseError.status = error.response?.status;
    if (error.response?.data instanceof Object) {
      responseError.jsonResponse = error.response!.data;
      if (responseError.jsonResponse.message) {
        responseError.displayMessage = responseError.jsonResponse.message;
      } else if (responseError.jsonResponse.errors) {
        [responseError.displayMessage] = responseError.jsonResponse.errors;
      }
    } else {
      responseError.textResponse = error.response?.data as string;
    }
    throw responseError;
  },
);
