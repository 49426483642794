import type { Form, FormQuestion } from '@/domain/form';
import type { Effect, Model, Reducer } from '@/models/connect';
import { FormService } from '@/services/forms';
import { handleError } from '@/utils/utils';
import { message } from 'antd';
import type { Operation } from 'fast-json-patch';

export type FormModelState = {
  forms: Form[];
  form: Partial<Form>;
};

type Effects = {
  search: Effect;
  findById: Effect;
  create: Effect;
  delete: Effect;
  createQuestion: Effect;
  updateQuestion: Effect;
  deleteQuestion: Effect;
  sortQuestions: Effect;
};

type Reducers = {
  reset: Reducer<FormModelState>;
  setForms: Reducer<FormModelState>;
  setForm: Reducer<FormModelState>;
};

const initialState = {
  forms: [],
  form: {},
};

const model: Model<FormModelState, Effects, Reducers> = {
  namespace: 'forms',
  state: { ...initialState },
  effects: {
    *search({ payload }, { call, put }) {
      const response = yield call(FormService.search, payload);

      yield put({
        type: 'setForms',
        payload: response,
      });
    },
    *findById({ payload }, { call, put }) {
      const response = yield call(FormService.findById, payload);

      yield put({
        type: 'setForm',
        payload: response,
      });
    },
    *create({ payload }, { call, put }) {
      yield call(FormService.create, payload);

      yield put({ type: 'search' });
    },
    *delete({ payload }, { call, put }) {
      yield call(FormService.delete, payload);

      yield put({ type: 'search' });
    },
    *createQuestion({ payload: { form, question } }, { call, put }) {
      const operation: Operation = {
        op: 'add',
        path: `/questions/${form.questions.length}`,
        value: question,
      };

      try {
        const updatedForm = yield call(FormService.patch, form.id, [operation], form.version);

        message.success('Question created.');

        yield put({ type: 'setForm', payload: updatedForm });
      } catch (error) {
        handleError(error, { toastFallbackMessage: 'Create question failed.' });
      }
    },
    *updateQuestion({ payload: { form, question, index } }, { call, put }) {
      const operations: Operation[] = Object.keys(question).map((key) => ({
        op: 'replace',
        path: `/questions/${index}/${key}`,
        value: question[key],
      }));

      try {
        const updatedForm = yield call(FormService.patch, form.id, operations, form.version);

        message.success('Question updated.');

        yield put({ type: 'setForm', payload: updatedForm });
      } catch (error) {
        handleError(error, { toastFallbackMessage: 'Update question failed.' });
      }
    },
    *deleteQuestion({ payload: { form, index } }, { call, put }) {
      const operations = [
        {
          op: 'remove',
          path: `/questions/${index}`,
        },
        // Fix the ranks for the remaining questions
        ...form.questions
          .filter((question: FormQuestion, thisIndex: number) => thisIndex !== index)
          .map((question: FormQuestion, thisIndex: number) => ({
            op: 'replace',
            path: `/questions/${thisIndex}/rank`,
            value: thisIndex + 1,
          })),
      ];

      try {
        yield call(FormService.patch, form.id, operations, form.version);

        message.success('Question deleted.');

        yield put({ type: 'findById', payload: form.id });
      } catch (error) {
        handleError(error, { toastFallbackMessage: 'Delete question failed.' });
      }
    },
    *sortQuestions({ payload: { form } }, { call, put }) {
      try {
        const updatedForm = yield call(FormService.update, form);
        yield put({ type: 'setForm', payload: updatedForm });
        message.success('Questions order updated successfully');
      } catch (error) {
        handleError(error, { toastFallbackMessage: 'Error updating the questions order' });
      }
    },
  },
  reducers: {
    reset() {
      return { ...initialState };
    },
    setForms(state, action) {
      return {
        ...state,
        forms: action.payload ?? [],
      };
    },
    setForm(state, action) {
      return {
        ...state,
        form: action.payload ?? {},
      };
    },
  },
};

export default model;
