import { SectionLoader } from '@propify/components';
import type { FC } from 'react';

type Props = {
  ['data-testid']?: string;
};

const SectionSplashScreen: FC<Props> = ({ ['data-testid']: dataTestId }) => {
  return <SectionLoader size={60} testId={dataTestId} />;
};

export default SectionSplashScreen;
